import React, { useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, Link, StaticQuery } from "gatsby";
import { getProduct } from "../../services/products";
import paragraphs from "lines-to-paragraphs";


import "react-responsive-carousel/lib/styles/carousel.min.css";
import ImageSlider from "../image/ImageSlider"; // requires a loader

const AtelierCollectionCard = ({ collection }) => {

  const imageStyle = { borderRadius: '0px', margin: 'auto', height : "250px", width:"100%", objectFit: "cover", justifyContent: "center", alignItems: "center", alignSelf: "center"   }

  return (
    <div className="is-parent column is-6" key={collection.id} style={{ alignItems : "stretch", display : "flex"}}>
      <article
        className={`tile is-child box atelier-type-item}`}
        style={{ borderRadius: 0, backgroundColor: "#dadada" }}
      >
        <div className="columns atelier-type-item is-fullheigh" style={{ margin: 0, alignItems : "stretch", height : "100%" }}>
          <div className="column is-6">
            {collection.image ? (
              <GatsbyImage
                image={collection.image.gatsbyImageData}
                alt={collection.image.altText}
                style={imageStyle}
              />
            ) : null}
          </div>

          <div className="column is-flex-direction-column is-flex is-justify-content-space-between is-fullheight">
            <div>
              <Link
                className="title has-text-primary is-size-5"
                to={"/ateliers"}
                state={{ title: collection.title}}
              >
                {collection.title}
              </Link>
              <div dangerouslySetInnerHTML={{ __html: paragraphs(collection.descriptionHtml) }} style={{ marginTop: 10 }} />
            </div>
            <Link className="button" to={"/ateliers"}
                  state={{ title: collection.title }}
                  style={{ marginTop: 10 }}>
              Voir les ateliers →
            </Link>
          </div>
        </div>
      </article>
    </div>
  )
}

export default AtelierCollectionCard
