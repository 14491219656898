import React from 'react'
import Layout from '../../components/layout/Layout'
import PreviewCompatibleImage from '../../components/image/PreviewCompatibleImage'
import { useIsMobile } from "../../hooks/useIsMobile";
import CeramiqueBanner from "../../components/ceramique/CeramiqueBanner";
import AutreAtelierCollectionRoll from "../../components/atelier/AutreAtelierCollectionRoll";
import GiftCardBanner from "../../components/gift-card/GiftCardBanner";

const imageInfo1 = {
  "alt": "Une photo de linogravure réalisé en atelier",
  "image": "/img/v2/Lautresateliers-min.jpg",
  "maxWidth": 250
}

const imageInfo2 = {
  "alt": "Une photo de la boutique de l atelier forma",
  "image": "/img/v2/Lautresateliers2-min.jpg.jpeg",
  "maxWidth": 250
}

const AutreAteliersPage = () => {


  const {isMobile} = useIsMobile()

  return (
    <Layout>
      <section className="section">
        <div className="container">

          <div className="content">
            <div className="columns">
              <div className="column is-12">
                <div className="columns is-multiline is-variable is-3" style={{ textAlign: 'justify' }}>
                  <div className="column is-6">
                    <p>L'atelier forma ne se limite pas à la céramique et vous offre la possibilité de découvrir la pratique d'artistes ou artisans grenoblois dont nous voulons mettre le talent en lumière. </p>
                    <p>L'idée est d'encourager au faire soi-même et d'éveiller en chacun sa part de créativité. </p>

                    <div style={{ marginTop : isMobile ? "0" : "4rem"}}>
                      <GiftCardBanner/>
                    </div>

                  </div>

                  <div className="column has-text-centered is-3 is-justify-content-center is-flex">
                    <PreviewCompatibleImage imageInfo={imageInfo2} />
                  </div>

                  <div className="column has-text-centered is-3 is-justify-content-center is-flex">
                    <PreviewCompatibleImage imageInfo={imageInfo1} />
                  </div>
                </div>
              </div>
            </div>


            <div className="content is-offset-1" style={{marginTop: '1rem', marginBottom: '1rem'}}>

              <p></p>
              <AutreAtelierCollectionRoll />
            </div>
          </div>
        </div>
      </section>
    </Layout >
  )}


export default AutreAteliersPage
